import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

import { Loader, Panel } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { systemDialogueCategories } from 'frontend/features/Library/constants';

import styles from '../Build.scss';
import { SpecialDialogueForm, SuggestionDialogueForm } from '../components';
import BuildEmptyState from '../components/BuildEmptyState/BuildEmptyState';
import { GetDialogue } from '../graphql';
import { useSpecialDialogue, useUpdateCurrentBreadcrumbs, useUpdateSpecialDialogue } from '../hooks';
import useCurrentRuleId from '../hooks/useCurrentRuleId';
import { emptyButton, handleButtonsAndImages } from '../utils';
import { prepareDialogueContextForFE } from '../utils/prepareDialogueBuildItem';

const EMPTY_OBJECT = {};

export default function UpdateSpecialDialogue() {
  const { loading: languagesLoading, dialogueType, selectedLanguage, botId, dialogueId } = useSpecialDialogue();

  const { data, loading } = useQuery(GetDialogue, { variables: { botId, dialogueId } });
  const dialogue = data?.dialogue ?? EMPTY_OBJECT;
  const onSubmit = useUpdateSpecialDialogue({ botId, dialogueId, selectedLanguage });
  const [hasChanges, setHasChanges] = useState(false);
  const currentRuleId = useCurrentRuleId(data?.dialogue?.dialogueRules);
  const initialValues = useMemo(
    () =>
      handleButtonsAndImages(selectedLanguage, {
        ...dialogue,
        ...(dialogue.context && { context: prepareDialogueContextForFE(dialogue.context) }),
        newButton: emptyButton,
      }),
    [selectedLanguage, dialogue],
  );

  useUpdateCurrentBreadcrumbs(data?.dialogue);

  if (loading || languagesLoading) {
    return (
      <Panel className={styles.loadingContainer}>
        <Loader size="large" />
      </Panel>
    );
  }
  if (!loading && isEmpty(dialogue)) return <BuildEmptyState />;

  if (
    dialogue.dialogueType === DIALOGUE_TYPES.SYSTEM_DIALOGUE &&
    dialogue.category === systemDialogueCategories.SUGGESTIONS
  ) {
    return <SuggestionDialogueForm initialValues={dialogue} />;
  }

  return (
    <SpecialDialogueForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      selectedLanguage={selectedLanguage}
      currentRuleId={currentRuleId}
      buildFormType={dialogueType}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
    />
  );
}
