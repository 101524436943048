// Currently there is no text in the rive animations, if we use text in rive animations we need to install '@rive-app/react-canvas'
import { Fit, Layout, type LayoutParameters, useRive } from '@rive-app/react-canvas-lite';
import React from 'react';

import BrokenLink from 'frontend/assets/animations/broken-link.riv';
import RobotBusy from 'frontend/assets/animations/robot_busy.riv';
import SearchConfused from 'frontend/assets/animations/search-confused.riv';

export type RiveProperties = {
  autoLoop?: boolean;
  layout?: LayoutParameters;
};

interface RiveAnimationProps {
  src?: string;
  className?: string;
  riveProperties?: RiveProperties;
  rotateRiveSources?: boolean;
}

const RIVE_SOURCES = [SearchConfused, BrokenLink, RobotBusy];

const RiveAnimation = ({ src, className, riveProperties, rotateRiveSources }: RiveAnimationProps) => {
  let riveSrc = src;
  if (!src && rotateRiveSources) {
    riveSrc = RIVE_SOURCES[Math.floor(Math.random() * RIVE_SOURCES.length)] as string;
  }
  const { RiveComponent, rive } = useRive({
    src: riveSrc,
    layout: new Layout({
      fit: Fit.Contain,
      ...(riveProperties?.layout && riveProperties.layout),
    }),
    autoplay: true,
    onStop: () => {
      // TODO figure out how to loop programatically
      if (riveProperties?.autoLoop) {
        rive?.reset();
        rive?.drawFrame();
      }
    },
  });
  return <RiveComponent className={className} />;
};

export default RiveAnimation;
