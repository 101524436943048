import cx from 'classnames';
import React from 'react';

import styles from './EmptyState.scss';
import Button, { type ButtonProps } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import RiveAnimation, { type RiveProperties } from '../RiveAnimation/RiveAnimation';

interface Props {
  children?: React.ReactNode;
  className?: string;
  /** Theme color of the component. */
  color?: keyof typeof COLOR_PAIRS;
  /** Description text. */
  description?: string;
  /** Icon component. */
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /** Component max-width. Accepts any valid value of the CSS property (e.g. %, em, px). */
  maxWidth?: string;
  title: string;
  riveSrc?: string;
  riveClassName?: string;
  riveProperties?: RiveProperties;
  rotateRiveSources?: boolean;
  iconWidth?: number;
  horizontal?: boolean;
  statusCode?: number;
  actionButtonProps?: ButtonProps;
}

const COLOR_PAIRS = {
  pink: ['--triad-pink-light', '--triad-pink-medium'],
  purple: ['--triad-purple-light', '--triad-purple-medium'],
  red: ['--triad-red-light', '--triad-red-medium'],
  yellow: ['--triad-yellow-light', '--triad-yellow-dark'],
  blue: ['--triad-blue-light', '--triad-blue-medium'],
  teal: ['--triad-teal-light', '--triad-teal-dark'],
  green: ['--triad-green-light', '--triad-green-dark'],
} as const;

const STATUS_CODES = {
  404: '404 error',
  500: '500 internal server error',
} as const;

/** Component to show the Empty State of a section.
 * It's context agnostic: it doesn't care where it's placed (e.g. no external margin); */
const EmptyState = ({
  children,
  className,
  color,
  title,
  description,
  icon,
  maxWidth,
  riveSrc,
  riveClassName,
  riveProperties,
  rotateRiveSources,
  statusCode,
  iconWidth = 56,
  horizontal = false,
  actionButtonProps,
}: Props) => {
  const customStyles: React.CSSProperties & {
    '--_backgroundColor'?: string;
    '--_iconColor'?: string;
    '--_maxWidth'?: string;
    '--_iconWidth'?: string;
  } = {};

  if (color) {
    const backgroundColor = COLOR_PAIRS[color][0];
    const iconColor = COLOR_PAIRS[color][1];
    customStyles['--_backgroundColor'] = `var(${backgroundColor})`;
    customStyles['--_iconColor'] = `var(${iconColor})`;
  }

  if (maxWidth) {
    customStyles['--_maxWidth'] = Number(maxWidth) ? `${maxWidth}px` : maxWidth;
  }

  if (iconWidth) {
    customStyles['--_iconWidth'] = `${iconWidth}px`;
  }

  return (
    <article
      className={cx(styles.container, className, { [styles.emptyStateHorizontal]: horizontal })}
      style={customStyles}
    >
      <div className={cx(styles.iconWrapper, { [styles.icon]: !!icon })}>
        {(riveSrc || rotateRiveSources) && (
          <RiveAnimation
            src={riveSrc}
            rotateRiveSources={rotateRiveSources}
            className={riveClassName}
            riveProperties={riveProperties}
          />
        )}
        {icon && <Icon component={icon} className={styles.icon} />}
      </div>
      <div className={styles.content}>
        {!!statusCode && STATUS_CODES[statusCode] && <p className={styles.statusCode}>{STATUS_CODES[statusCode]}</p>}
        <h4 className={styles.title}>{title}</h4>
        {!!description && <p className={styles.description}>{description}</p>}
        {children}
        {actionButtonProps && (
          <div className={styles.actionButtonWrapper}>
            <Button {...actionButtonProps} />
          </div>
        )}
      </div>
    </article>
  );
};

export default EmptyState;
