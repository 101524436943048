import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EmptyState, Panel } from 'frontend/components';
import { useBotOrSkill } from 'frontend/hooks';

import styles from './BuildEmptyState.scss';

const BuildEmptyState = () => {
  const navigate = useNavigate();
  const { botId, skillId } = useBotOrSkill();

  return (
    <Panel>
      <EmptyState
        className={styles.emptyState}
        statusCode={404}
        iconWidth={124}
        rotateRiveSources
        horizontal
        actionButtonProps={{
          text: 'Back to home',
          color: 'primary',
          onClick: () => navigate(`/workspace/${botId || skillId}/build`),
        }}
        description="It appears we've taken a wrong turn. We'll have you back on track in no time."
        title="Page not found"
      />
    </Panel>
  );
};

export default BuildEmptyState;
