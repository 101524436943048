import { useApolloClient, useMutation } from '@apollo/client';
import { isEmpty, mapValues, pick } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { buildTypes } from 'frontend/constants';
import { BuildIdObjectType, IDType } from 'frontend/propTypes';

import { DialogueForm } from '../components';
import BuildEmptyState from '../components/BuildEmptyState/BuildEmptyState';
import { GetDialogue, SaveDialogueMod } from '../graphql';
import { InitialValuesType } from '../propTypes';
import { getBuildItemUpdate, getButtonsUpdate, getImageCarouselsUpdate, modDialogueInputFields } from '../utils';
import getRulesUpdate from '../utils/getRulesUpdate';

const DialogueMod = ({
  initialValues,
  hasChanges,
  setHasChanges,
  currentLanguage,
  currentRuleId,
  buildIdObject,
  buildId,
  mergeWithExternalChanges,
  updateInitialValues,
  reset,
}) => {
  const client = useApolloClient();
  const [updateMutation] = useMutation(SaveDialogueMod);

  const onSubmit = useCallback(
    async (values, form) => {
      const fixedValues = {
        ...values,
        modWebhookUrls: values.modWebhookUrls || '',
        modVideoSources: mapValues(values.modVideoSources || {}, (url) => url || ''),
      };

      const { initialValues: initial } = form.getState();
      const dialogueId = initial.id;
      const botId = buildId;

      // Non-related fields must be explicitly merged with external changes to avoid unintended overwrites
      const nonRelatedFieldsUpdate = mergeWithExternalChanges({ ...pick(fixedValues, modDialogueInputFields) });

      // Related fields
      const sampleUpdate = getBuildItemUpdate(initial, values, 'modSamples');
      const buttonsUpdate = getButtonsUpdate(initial, values, 'mod');
      const replyUpdate = getBuildItemUpdate(initial, values, 'modReplies');
      const imageCarouselsUpdate = getImageCarouselsUpdate(initial, values, { prefix: 'mod' });
      const conditionsUpdate = getRulesUpdate({
        initialValues: initial,
        values,
        language: currentLanguage,
        prefix: 'mod',
      });

      const dialogueModInput = {
        ...nonRelatedFieldsUpdate,
        ...sampleUpdate,
        ...replyUpdate,
        ...buttonsUpdate,
        ...imageCarouselsUpdate,
        ...conditionsUpdate,
      };

      const variables = {
        botId,
        dialogueId,
        languageCode: currentLanguage,
        dialogueModInput,
      };

      const { data: updatedData } = await updateMutation({ variables });

      const { dialogue: existingDialogue } = client.readQuery({
        query: GetDialogue,
        variables: { botId, dialogueId },
      });
      const updatedDialogue = {
        ...existingDialogue,
        mod: updatedData.saveDialogueMod,
      };

      client.writeQuery({
        query: GetDialogue,
        variables: { botId, id: dialogueId },
        data: { dialogue: updatedDialogue },
      });

      setHasChanges(false);
      updateInitialValues({ dialogue: updatedDialogue }, currentLanguage);
      reset();
    },
    [
      buildId,
      client,
      currentLanguage,
      mergeWithExternalChanges,
      reset,
      setHasChanges,
      updateInitialValues,
      updateMutation,
    ],
  );

  if (isEmpty(initialValues)) {
    return <BuildEmptyState />;
  }

  return (
    <DialogueForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      selectedLanguage={currentLanguage}
      currentRuleId={currentRuleId}
      buildIdObject={buildIdObject}
      buildType={buildTypes.BOT}
      buildId={buildId}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      isModDialogue
    />
  );
};

DialogueMod.propTypes = {
  initialValues: InitialValuesType.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  currentRuleId: PropTypes.string,
  buildIdObject: BuildIdObjectType.isRequired,
  buildId: IDType.isRequired,
  mergeWithExternalChanges: PropTypes.func.isRequired,
  updateInitialValues: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default DialogueMod;
